/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-2xl font-bold flex flex-row items-center text-white leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
              href="/"
            >
              <img src="/wizardofswipe-nobg.png" alt="logo" className="h-12 mr-4" />
              Wizard of Swipe
            </a>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none text-white"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-transparent lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto bg-transparent">
              <li className="flex flex-col md:flex-row gap-4 items-end md:items-center py-2 bg-transparent">
                <a href="https://billing.stripe.com/p/login/7sI8yUamwfLl2isaEE" target="_blank" rel="noreferrer">
                  <span className="text-white font-medium text-sm px-2 py-2 text-center">
                    Manage Subscription
                  </span>
                </a>
                <a href="https://buy.stripe.com/dR68yH3QRdll8iQ7sv">
                  <button type="button" className="text-white uppercase bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-4 py-2 text-center">
                    Upgrade Tinder
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
